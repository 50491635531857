/*!

=========================================================
* Vue Light Bootstrap Dashboard - v1.4.1
=========================================================

* Product Page: http://www.creative-tim.com/product/vue-light-bootstrap-dashboard-pro
* Copyright 2019 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc";
@import "lbd/sidebar-and-main-panel";

@import "lbd/buttons";
@import "lbd/social-buttons";
@import "lbd/inputs";

@import "lbd/alerts";
@import "lbd/tables";

@import "lbd/checkbox-radio-switch";
@import "lbd/navbars";
@import "lbd/footers";
@import "lbd/tabs-navs-pagination";
@import "lbd/progress_bars";


// Fancy Stuff
@import "lbd/cards";
@import "lbd/chartist";
@import "lbd/tooltip";
@import "lbd/responsive";
@import "lbd/bootstrap-switch";

//plugins css
@import "lbd/plugins/animate";
@import "lbd/plugins/sweetalert2";
@import "lbd/plugins/fullcalendar";
@import "lbd/plugins/chartist";
@import "lbd/plugins/perfect-scrollbar";
@import "lbd/plugins/vue-tabs";

// element-ui customizations
@import "lbd/element/collapse";
@import "lbd/element/tags";
@import "lbd/element/tables";
@import "lbd/element/slider";
@import "lbd/element/date_picker";
@import "lbd/element/select";
@import "lbd/element/input";
@import "lbd/element/modal";

// pages
@import "lbd/pages";

.el-select {
    border: none;
    
    :hover {
        color: #606266 !important;
    }

    .el-input {
        border: none;
    }
    
    input {
        border: 1px solid #C0C4CC;
        border-radius: 5px;    
    }

}

.el-input, .el-textarea {
    border: none;

    :hover {
        color: #606266 !important;
    }

    input, textarea {
        border: 1px solid #C0C4CC;
        border-radius: 5px;
    }
}

.minIcon {
    font-size: 1em !important;
    padding: 8px 0;
}

.ticket {
    &.open {
        color: rgb(0, 165, 22);
    }

    &.sla-0 {
        background-color: rgb(199, 0, 0);
        color: #fff;
        padding: 2px 1px;
    }

    &.sla-1 {
        background-color: rgb(12, 115, 233);
        color: #fff;
        padding: 2px 1px;
    }

    &.sla-2 {
        background-color: rgb(221, 236, 12);
        padding: 2px 1px;
    }

    &.sla-base {
        background-color: rgb(150, 150, 150);
        color: #fff;
        padding: 2px 1px;
    }
}

.mailText {
    &.halfHeight {
        height: 200px;
        overflow-y: auto;
    }

    &.fullHeight {
        height: calc(100% - 10px);
    }
}

.sys-bg-green {
    background-color: $color-green;
}